<template>
    <div class="page-box">
        <headBar ref="headerHeight" title="超鹿团课健身卡" left-arrow @click-left="newAppBack">
          <template slot="right">
            <img @click="showShare = true" class="share-icon" src="https://img.chaolu.com.cn/ACT/invite-2022/share-icon.png" alt="">
          </template>
        </headBar>
        <app-page id="box" class="page-img">
            <van-sticky :offset-top="headerHeight">
                <div class="city-nav-box">
                    <div @click="cityChange(item)" :class="{ active: item.cityId === currentCityId }"
                         v-for="(item, index) in cityList" :key="index">
                        {{ item.areaName }}卡
                    </div>
                </div>
            </van-sticky>


            <div class="page-h" v-if="selectItem.equityCardItemId">
                <div :style="`background-image: url(${cardInfo.backgroundImgUrl})`">
                    <div>
                        <h3>{{ cardInfo.equityCardName }}</h3>
                        <!--                        <h3><span>¥</span>{{ salePrice | priceFormat }}</h3>-->
                    </div>
                    <!--<p v-if="selectItem.equityCardItemValidity"><span>有效期至{{selectItem.equityCardItemValidity}}{{ selectItem.isAutoRenew === 1 ? '（自动续费）' : '' }}</span></p>-->

                    <!-- 注释原来说明
                              <p v-if="cardInfo.isHaveCard"><span>续费开卡顺延生效<span v-if="selectItem.openValidity">，超出{{ selectItem.openValidity }}天未进馆自动退款</span></span></p>
                              <p v-else><span>下次进馆生效<span v-if="selectItem.openValidity">，超出{{ selectItem.openValidity }}天未进馆自动退款</span></span></p>
                              -->
                    <p><span>有效期{{ selectItem.useValidity }}天 | 随时激活使用</span></p>
                </div>
            </div>
            <div v-else-if="finished" class="page-h" style="padding: 0 4.266vw;box-sizing: border-box">
                <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/empty_bg.png" alt=""
                     style="width: 100%;height: 71.2vw;">
            </div>

            <!--  是否优惠券展示-->
            <!--            <div v-if="activeList.length" class="voucher row-start-center">-->
            <!--                <div @click="jumpCoupon" class="row-center-center">-->
            <!--                    <span>最高立减 ¥{{ topPrice }}</span><van-icon name="arrow" />-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="card-box" v-show="selectItem.equityCardItemId">
                <div class="gift-detail"
                     v-if="selectItem.equityCardEquityVOList && selectItem.equityCardEquityVOList.length">
                    <div class="gift-detail-bottom">
                        <div class="gift-detail-item" v-for="(item, index) in selectItem.equityCardEquityVOList"
                             :key="index">
                            <span class="gift-sort row-center-center">权益{{ index + 1 }}</span>
                            <img :src="item.equityIntroduceImgUrl" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectItem.equityCardItemId" class="bottom-rule">
                <h4>团课卡权益使用规则</h4>
                <!-- 广州赠送不同 规则不同 -->
                <p>{{ currentCityId === '818447834079563776' ? scheduleRuleGz : scheduleRule }}</p>
            </div>

            <div v-if="selectItem.equityCardItemId" class="card-box bottom-btn">
                <div v-if="selectedCouponId && voucherList.length" class="select-voucher" @click="showCoupon = true">
                    <div class="row-start-center flex-auto">
                        <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_tips.png" alt="">已减 <span>{{ selectedDiscount }}元</span>
                        <span class="row-start-center">，
                             <van-count-down @finish="timeFinish" :time="selectedCouponTips">
                              <template #default="timeData">
                                <span style="font-size: 3.2vw;line-height: 4.27vw">{{ timeData.days }}天{{ timeData.hours }}时{{ timeData.minutes }}分</span>
                              </template>
                            </van-count-down>
                        </span>后失效
                    </div>
                    <p><span>{{voucherList.length}}张</span><van-icon name="arrow" /></p>
                </div>
                <div v-else-if="voucherList.length" class="select-voucher" @click="showCoupon = true">
                    <div class="row-start-center"><img
                        src="https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_tips1.png" alt=""><span
                        class="fw6">我的优惠券</span></div>
                    <p>{{ voucherList.length }}张<van-icon name="arrow"/></p>
                </div>

                <div class="buy-card-btn row-between-center">
                    <h4>
                        <span class="s1">¥&nbsp;&nbsp;</span><span class="s2">{{ salePrice | priceFormat }}</span>
                        <span v-if="selectItem.originalPrice" class="s3">¥{{
                                selectItem.originalPrice | priceFormat
                            }}</span>
                    </h4>
                    <div><p @click="checkCity()" class="row-center-center no-fast-click"></p></div>
                </div>
                <div class="protocol" :class="{ shake: shake }">
                    <p @click="protocol = !protocol" :class="{ active: protocol }"></p>
                    <div><span @click="protocol = !protocol">点击购买支付即代表您已阅读并同意</span><span class="s1" @click.stop="checkProtocol(true)">《超鹿运动团课月卡购买服务协议》</span></div>
                </div>

            </div>
        </app-page>
        <!--支付成功弹窗-->
        <van-popup v-model="showPaySuccess" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false" @closed="closePaySuccess">
            <div class="pay-box big">
                <div class="pay-box-title success">

                    <div class="row-center-center">
                        <img class="small-right"
                             src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/group12.png"
                             alt="">
                        购买成功
                    </div>
                    <img class="pay-success-tips" src="https://img.chaolu.com.cn/ACT/schedule-2023/pay_success_tips.png"
                         alt="">
                </div>
                <div class="pay-equit-box flex flex-wrap">
                    <img v-for="item, index in selectItem.equityImages" class="pay-box-item" :src="item" :key="index"
                         alt="">
                </div>
                <div class="pay-share-btn row-between-center">
                    <div class="grey-btn" @click="dontActived">暂不激活</div>
                    <div class="fw6" @click="immediateActived">立即激活生效</div>
                </div>
                <div class="pay-box-tips">会员卡已到账至 “个人中心-卡包”</div>
                <div v-if="appTypeStr !== 'mini'" class="pay-box-tips" style="margin-top: 1.07vw">
                    请升级至最新版APP查看详情
                </div>
            </div>
            <!-- <img @click="showPaySuccess = false" class="result-close"
              src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png" alt=""> -->
        </van-popup>

        <!--购买成功弹窗 有多张卡 无法立即激活的情况-->
        <van-popup v-model="showPayUnActivedSuccess" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false" @closed="closePaySuccess">
            <div class="pay-box">
                <div class="pay-box-title">
                    <img
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/group12.png"
                        alt="">
                    <div>购买成功!</div>
                </div>

                <div class="pay-share-btn row-between-center">
                    <div class="grey-btn" @click="dontActived">返回</div>
                    <div class="fw6" @click="jumpMyBagCopy">去卡包查看</div>
                </div>
                <div class="pay-box-tips">会员卡已到账至 “个人中心-卡包”</div>
                <div v-if="appTypeStr !== 'mini'" class="pay-box-tips" style="margin-top: 1.07vw">
                    请升级至最新版APP查看详情
                </div>
            </div>
            <img @click="showPayUnActivedSuccess = false" class="result-close"
                 src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png"
                 alt="">
        </van-popup>

        <!--激活成功弹窗-->
        <van-popup v-model="showActivateSuccess" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false" @closed="closePaySuccess">
            <div class="pay-box">
                <div class="pay-box-title">
                    <img
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/group12.png"
                        alt="">
                    <div>激活生效成功!</div>
                </div>
                <!--                <div class="pay-box-text">分享活动给好友，一起来超鹿运动吧！</div>-->
                <div style="margin-top: 15vw" class="pay-share-btn row-between-center">
                    <div class="fw6" @click="immediateUse()">立即使用权益</div>
                    <!--                    <div @click="jumpSendGift()">赠送亲友</div>-->
                </div>
            </div>
            <img @click="showActivateSuccess = false" class="result-close"
                 src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png"
                 alt="">
        </van-popup>


        <!--错误弹窗提示-->
        <van-popup v-model="showError" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false">
            <div class="error-box">
                <h4>提示</h4>
                <p>{{ errorMsg.msg }}</p>
                <div @click="showError = false" class="row-center-center">我知道了</div>
            </div>
        </van-popup>
        <!--城市确认-->
        <van-popup v-model="showCheckCity" :overlay="true" style="background-color: transparent;width: 84vw;" closeable>
            <div class="error-box">
                <h4>提示</h4>
                <p>您当前购买的团课健身卡，仅限<span style="font-weight: bold;color: #ED5C42">{{ cityName }}</span>所有门店使用，请确认后再付款！
                </p>
                <div @click="checkBuy" class="row-center-center">我知道了</div>
            </div>
        </van-popup>
        <!-- 协议弹窗 -->
        <van-popup v-model="showProtocol" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false">
            <div class="protocol-box">
                <h4>“超鹿运动”团课月卡服务协议</h4>
                <p>{{ protocolRule }}</p>
                <div v-if="doneProtocol" @click="showProtocol = false" class="row-center-center">我知道了</div>
                <div v-else class="row-center-center gray">请认真阅读以上内容({{ timeProtocol }})</div>
            </div>
        </van-popup>
        <!--自动支付弹窗-->
        <van-popup round v-model="showAutoPay" position="bottom" closeable :close-on-click-overlay="false">
            <div class="refund-box">
                <div class="refund-title">自动续费签约</div>
                <div class="refund-content">自动续费项目需要进行两笔支付签约，签约完成后再进行付款完成购买哦</div>
                <div class="pay-img">
                    <img v-if="isFirstContract"
                         src="https://img.chaolu.com.cn/ACT/sell-membership-2022/auto_pay1.png" alt="">
                    <img v-else src="https://img.chaolu.com.cn/ACT/sell-membership-2022/auto_pay2.png" alt="">
                </div>
                <div v-if="!isFirstContract" class="refund-info row-between-center">
                    <span>购买内容：</span>
                    <span>{{ selectItem.equityCardItemName }}(自动续费)</span>
                </div>
                <div v-if="!isFirstContract" class="refund-info  row-between-center">
                    <span>价格：</span>
                    <span>¥{{ selectItem.salePrice }}</span>
                </div>
                <div class="refund-btn row-between-center">
                    <div v-if="isFirstContract" @click="autoRenew()">完成第1笔签约</div>
                    <div v-else @click="autoRenew()">完成第2笔签约并支付</div>
                </div>
            </div>
        </van-popup>

        <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"></payPopupCard>
        <coupon :dateTips="selectedCouponTips" :unavailableList="unavailableList" :discount="selectedDiscount" :couponId="selectedCouponId" :list="voucherList" @confirm="confirmVoucher" :showCoupon.sync="showCoupon"></coupon>
        <!--  分享  -->
        <common-share
            :value="showShare"
            :shareParams="shareParams"
            :shareItem="['minifriend']"
            @close="showShare = false"
            @share-success="showShare = false"
        >
        </common-share>
    </div>
</template>
<script>
import userMixin from '@/mixin/userMixin';
import headBar from '@/components/app/headBar';
import appPage from "@/common/components/appPage";
import commonShare from "@/components/commonShare";
import payPopupCard from "@/components/pay-popup-card";
import coupon from "@/components/pay-popup-card/coupon";
import {
    appPay,
    appPaySuccess,
    initBack,
    newAppBack,
    appAutoRenewPay,
    enterForeground,
    getAppVersion, closeWindow
} from "@/lib/appMethod";
import wx from "weixin-js-sdk";
import {compareVersion, getParam} from "@/lib/utils";
import {scheduleProtocol, scheduleRule, scheduleRuleGz} from "@/views/month-card/const";

export default {
    data() {
        return {
            chinaNum: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
            time: 0,
            activityStatus: 0, // 活动状态 1 活动未开始 2 活动进行中 3 活动已结束
            showShare: false,
            ruleShow: false,
            shareParams: {
                title: '上团课，就购团课月卡；享多重优惠；',
                miniImage: 'https://img.chaolu.com.cn/MINI/minicard/4.png',
                path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/sell-membership',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                multiple: "0.95",
                userName: "gh_7f5b57b6a76e",
            },
            userPoster: '',
            headImg: '',
            nickName: '',
            showCoupon: false,
            showPaySuccess: false,
            showPayUnActivedSuccess: false,
            showActivateSuccess: false,
            showRule: false,
            showAutoPay: false,
            showCheckCity: false, // 城市确认弹窗
            isShowCheckCityName: '', // 弹出城市确认弹窗的城市
            type: 0,
            selectItem: {}, // 当前选择卡
            cardInfo: {
                equityCardItemVOList: []
            },
            param: {},
            canIUse: true, // 当前版本是否可用
            protocol: false, // 勾选用户协议
            showProtocol: false, // 勾选用户协议
            doneProtocol: false,//已阅读完协议
            timeProtocol: window.location.origin.indexOf('web.chaolu.com') > -1 ? 6 : 1, // 阅读倒计时
            currentCityId: '3', // 默认福州
            cityName: '福州市',
            showChoseCity: false,
            isFirstContract: true,
            showError: false,
            shake: false,
            errorMsg: {},
            finished: false,
            scheduleRule,
            scheduleRuleGz,
            protocolRule: scheduleProtocol,

            openCardItem: [],
            cityList: [],
            headerHeight: 0,
            showPayPopup: false,
            payData: {},
            payUrl: '',
            voucherList: [],
            unavailableList: [], //不可用列表
            selectedDiscount: 0,
            selectedCouponId: '',
            selectedCouponTips: '', // 失效提示
            appVersion: 0, // app版本号
            canIUseSilentAppPay: false, // 能否使用web弹窗支付
            // canIUseGiveCard: false, // 能否使用赠卡功能
            topPrice: 0, // 最高立减
            activeList: [], // 可用卡列表
            channelCode: '', // 购买来源

            equityCardUserId: '', // 激活卡使用
        };
    },
    mixins: [userMixin],
    components: {
        appPage,
        commonShare,
        headBar,
        payPopupCard,
        coupon
    },
    filters: {
        priceFormat(v) {
            if (!v) {
                return 0
            }
            return parseFloat(v)
        }
    },
    mounted() {
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.showShare = false
                console.log(7)
                if (this.userId && this.token) {
                    // this.getActivityInfo();
                }
            } else if (document.visibilityState === "hidden") {
                this.showShare = false
                this.showPaySuccess = false
                this.showPayUnActivedSuccess = false
                this.showActivateSuccess = false
                console.log(6)
            }
        })
        if (this.appTypeStr !== 'mini') {
            setTimeout(() => {
                this.headerHeight = this.$refs.headerHeight.$el.clientHeight || 0
            }, 1500)
        }
    },
    computed: {
        salePrice() {
            // 不选券使用限时售卖价
            if (!this.selectedDiscount || !this.selectedCouponId) {
                console.log(this.selectItem.salePrice)
                return this.selectItem.salePrice
            }
            // 最终售价
            let p = this.selectItem.salePrice || 0
            let voucherAfter = this.selectedDiscount || 0
            let price = (p - voucherAfter) < 0 ? 0 : (p - voucherAfter)
            return price.toFixed(2)
        }
    },
    async created() {
        initBack();
        await this.$getUserId();
        this.currentCityId = await this.$getCityId() || '3'
        this.currentCityId = this.$route.query.cardCityId || this.currentCityId
        if (this.$route.query.urlVenueId) {
            this.urlVenueId = this.$route.query.urlVenueId
            let r = await this.getVenueInfo()
            if (r.cityId) {
                this.currentCityId = r.cityId
                this.cityName = r.cityName
            }
        }
        if (this.$route.query.channelCode) {
            this.channelCode = this.$route.query.channelCode
        }
        this.getCityList()
        console.log(this.currentCityId)
        await this.getCardList(true)
        // APP 切换到前台 监听是否再次弹起续费
        enterForeground(() => {
            let autoRenew = sessionStorage.getItem('autoRenew')
            console.log('app回前台', autoRenew)
            console.log(4)
            if (autoRenew) {
                console.log('11111')
                console.log(5)
                this.checkAuto()
            }
        })
        if (this.appTypeStr === 'mini') {
            appPaySuccess().then(res => {
                this.checkCanActived()
            })
        }
        try {
            this.appVersion = await getAppVersion()
            this.canIUse = (compareVersion(this.appVersion, '2.20.11') >= 0)
            this.canIUseSilentAppPay = (compareVersion(this.appVersion, '2.31.22') >= 0)
            // this.canIUseGiveCard = (compareVersion(this.appVersion, '2.32.22') >= 0)
        } catch (e) {
            this.canIUse = false
            this.canIUseSilentAppPay = false
            // this.canIUseGiveCard = false
            this.appVersion = 0
        }
        console.log('当前版本', this.appVersion)
        this.clickId = this.$route.query.clickId
        this.addUserAction('CONFIRM_EFFECTIVE_LEADS')

        const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership`)
        this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
        wx.miniProgram.postMessage({
            data: {
                type: 'share',
                shareImage: this.shareParams.miniImage,
                shareTitle: this.shareParams.title,
                shareUrl: this.shareParams.path,
            },
        })
    },
    methods: {
        newAppBack,
        confirmVoucher(item) {
            if (item) {
                this.selectedDiscount = item.discount || 0
                this.selectedCouponId = item.id || ''
                let time = Math.round(item.expireDayTime*1000 - (new Date().getTime())) || 0
                this.selectedCouponTips = time < 0? 0: time
            }
        },
        // 获取我的优惠券最高金额  及 可用和不可用列表
        getVoucherList(cardItemId) {
            this.$axios.post(`${this.baseURLApp}/voucher/equityCardVoucher`,  {
                userId: this.userId,
                token: this.token,
                cardItemIds: [cardItemId],
            }, false).then(res => {
                let data = res.data.list[0] ?? {}
                this.voucherList = data.availableList  // 可用列表
                this.unavailableList = data.unavailableList // 不可用列表

                this.selectedDiscount = data.maxDiscount || 0
                this.selectedCouponId = data.maxDiscountVoucherId || ''
                let time = Math.round(data.expireDayTime*1000 - (new Date().getTime())) || 0
                this.selectedCouponTips = time < 0? 0: time
                console.log(this.selectedCouponTips)
            })
        },
        // 倒计时结束刷新优惠券
        timeFinish() {
            // this.getVoucherList(this.selectItem.equityCardItemId) // 请求用户的优惠券
        },
        getVenueInfo() {
            return new Promise(resolve => {
                this.$axios.post(`${this.baseURLApp}/newVenue/getVenueInfo`, {
                    userId: this.userId,
                    token: this.token,
                    venueId: this.urlVenueId
                }).then(res => {
                    if (this.userId == 233404 || this.userId == 85423 || this.userId == 9629028 || this.userId == 250978) {
                        alert(res.data.venueName)
                    }
                    resolve({cityId: res.data.cityId, cityName: res.data.cityName})
                }).catch(() => {
                    resolve({cityId: '3', cityName: "福州市"})
                })
            })
        },
        getCityList() {
            this.$axios.post(`${this.baseURLApp}/scheduleLesson/cityList`, {userId: this.userId}).then(res => {
                this.cityList = res.data
                let item = this.cityList.find(r => {
                    return r.cityId === this.currentCityId
                })
                if (item) {
                    this.cityName = item.areaName
                    this.currentCityId = item.cityId
                }
            })
        },
        cityChange(v) {
            this.cityName = v.areaName
            this.currentCityId = v.cityId
            this.getCardList()

            // 修改分享参数
            const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership`)
            this.shareParams.path = `/pages/webView/index?cardCityId=${this.currentCityId}&webUrl=${uu}`
            wx.miniProgram.postMessage({
              data: {
                type: 'share',
                shareImage: this.shareParams.miniImage,
                shareTitle: this.shareParams.title,
                shareUrl: this.shareParams.path,
              },
            })
        },
        getCardList(isFirst) {
            return new Promise((resolve, rej) => {
                this.$toast.loading({
                    forbidClick: true,
                    loadingType: 'spinner',
                    duration: 0,
                    message: '加载中...'
                });
                this.$axios.post(`${this.baseURLApp}/equityCard/scheduleCardList`, {
                    userId: this.userId,
                    token: this.token,
                }).then(res => {
                    this.finished = true
                    this.$toast.clear()
                    this.cardInfo = res.data.find(row => {
                        return row.cityId === this.currentCityId
                    })?.equities[0]

                    this.selectItem = this.cardInfo?.equityCardItemVOList?.length ? this.cardInfo.equityCardItemVOList[0] : {}
                    this.getVoucherList(this.selectItem.equityCardItemId) // 请求用户的优惠券
                    this.selectItem.equityImages = this.selectItem.equityImages ? this.selectItem.equityImages.split(',') : []

                    this.$toast.clear()
                    this.$forceUpdate()
                    resolve()

                }).catch(() => {
                    this.finished = true
                    rej()
                })
            })
        },
        async selectCard(v) {
            await this.getVoucherList(v.equityCardItemId) // 请求用户的优惠券
            this.selectItem = v
        },
        // 校验是否阅读完月卡协议
        checkProtocol(show) {
            if (!this.doneProtocol) {
                this.showProtocol = true
                let timer = setInterval(() => {
                    this.timeProtocol--
                    if (this.timeProtocol < 1) {
                        clearInterval(timer)
                        this.doneProtocol = true
                        this.protocol = true
                    }
                }, 1000)
                return false
            } else {
                if (show) {
                    this.showProtocol = true
                }
                return true
            }
        },
        // 提示购买城市
        checkCity() {
            if (this.isShowCheckCityName && this.isShowCheckCityName === this.cityName) {
                this.checkBuy()
                return false
            }
            this.showCheckCity = true
            this.isShowCheckCityName = this.cityName
        },
        // 校验是否可以买月卡
        checkBuy() {
            this.showCheckCity = false
            if (!this.checkProtocol()) {
                return false
            }
            if (!this.protocol) {
                this.shake = true
                this.$toast({
                    message: '请仔细阅读并勾选购买协议！',
                    forbidClick: true,
                    onClose: () => {
                        this.shake = false
                    }
                })
                return false
            }
            let param = {
                equityCardItemId: this.selectItem.equityCardItemId,
                userId: this.userId,
                token: this.token,
                source: 'SELF_BUY'
            }
            this.$axios.post(`${this.baseURLApp}/equityCard/check/buy`, param, false).then(res => {
                if (res.data) {
                    this.buyCard()
                }
            }).catch((r) => {
                this.errorMsg = {msg: r.msg, showAutoRenew: r.data ? r.data.showAutoRenew : 0}
                this.showError = true
            })
        },
        // 周期支付
        autoRenew() {
            let info = this.selectItem
            sessionStorage.setItem('autoRenew', '1')
            appAutoRenewPay({
                equityCardItemId: info.equityCardItemId,
                equityCardId: info.equityCardId,
                userId: this.userId
            })
        },
        // 检查周期支付是否完成
        checkAuto() {
            this.$toast.loading({
                forbidClick: true,
                loadingType: 'spinner',
                duration: 0,
                message: '加载中...'
            })
            let info = this.selectItem
            let param = {
                equityCardItemId: info.equityCardItemId,
                equityCardId: info.equityCardId,
                userId: this.userId
            }
            this.$axios.post(`${this.baseURLApp}/msCard/cyclePaySign/query`, param).then(res => {
                this.$toast.clear()
                let data = res.data || {}
                console.log(1)
                if (res.data.hasNextSign > 0) {
                    console.log(2)
                    this.showAutoPay = true
                    this.isFirstContract = (data.needSignAmount === data.totalAmount)
                } else if (sessionStorage.getItem('autoRenew') && data.hasSignedAmount === data.totalAmount) {
                    this.showPaySuccess = true
                    console.log(3)
                    this.showAutoPay = false
                }
            }).catch(() => {
                this.$toast.clear()
            })
        },
        closePaySuccess() {
            sessionStorage.setItem('autoRenew', '')
        },
        // 购买
        buyCard() {
            // 点击购买页 上报
            this.addUserAction('COMPLETE_ORDER')
            let paramUrl = getParam().url
            localStorage.setItem('paramUrl', paramUrl)
            let equityCardItemId = getParam().equityCardItemId || ''
            let url = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership&fromOrder=${paramUrl ? 1 : 0}&equityCardItemId=${equityCardItemId}&userId=1&cityId=1&urlVenueId=${this.$route.query.urlVenueId || ''}`
            let info = this.selectItem
            let payData = {
                subject: info.equityCardItemName,
                extendsJson: {
                    appVersion: '2.6.11',
                    seriesName: info.equityCardItemName,
                    discountId: this.selectedCouponId,
                    sourceChannel: this.channelCode || '',
                    // isImmediatelyOpen
                },
                orderEquityCardCreateReq: {
                    source: 'SELF_BUY'
                },
                totalAmount: info.salePrice,
                venueId: this.urlVenueId || '31',
                goodsNum: "1",
                isApp: '1',
                type: '19',
                selectedDiscount: this.selectedDiscount,
                discountId: this.selectedCouponId,
                userId: this.userId,
                token: this.token,
                cityId: this.currentCityId,
                extendsType: '4',
                mouldId: info.equityCardItemId,
                whetherPrivacy: 0,
                originalPriceStr: info.originalPrice,
                title: '购买内容',
            }
            if (this.appTypeStr === 'mini') {
                payData.wxPayType = 'applet'
            }
            // 安卓判断是否支持
            if (!this.canIUseSilentAppPay && this.appTypeStr === 'and') {
                payData.totalAmount = this.salePrice
                console.log(payData)
                appPay(payData, url)
            } else {
                this.showPayPopup = true
                this.payData = payData
                this.payUrl = url
                console.log(payData)
            }
            if (this.appTypeStr !== 'mini') {
                appPaySuccess().then(res => {
                  console.log('iossss');
                    this.checkCanActived()
                    this.getCardList();
                })
            }
        },
        checkCanActived() {
            this.$axios.post(`${this.baseURLApp}/user/equityCard/isActiveEquityCardUser`, {
                type: this.cardInfo.type,
                userId: this.userId,
                token: this.token,
            }).then(res => {
                this.equityCardUserId = res.data.equityCardUserId
                this.equityCardUserId ? this.showPaySuccess = true : this.showPayUnActivedSuccess = true
            })
        },
        jumpMyBagCopy() {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/month-card/my-card&userId=1&token=1`
                })
            } else {
                this.$router.push({
                    path: '/month-card/my-card',
                })
            }
        },
        // 跳转订单页面
        jumpMyOrder() {
            if (this.appTypeStr === 'mini') {
                const url = localStorage.getItem('paramUrl')
                wx.miniProgram.redirectTo({
                    url: decodeURIComponent(url)
                })
            } else {
                closeWindow()
            }
        },
        // 暂不激活
        dontActived() {
            console.log(getParam())
            console.log(getParam().fromOrder == 1 || getParam().equityCardItemId)
            console.log(getParam().fromOrder == 1 , getParam().equityCardItemId)
            if (getParam().fromOrder == 1 || getParam().equityCardItemId) {
                this.jumpMyOrder()
            } else {
                this.showPaySuccess = false
                this.showPayUnActivedSuccess = false
            }
        },
        // 立即激活
        immediateActived() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/user/equityCard/open`, {
                userId: this.userId,
                token: this.token,
                userEquityCardId: this.equityCardUserId
            }).then((res) => {
                this.$toast.clear()

                this.showPaySuccess = false
                this.showActivateSuccess = true
            }).catch(() => {
                this.$toast.clear()
            })
        },
        // 立即使用
        immediateUse() {
            if (getParam().fromOrder == 1 || getParam().equityCardItemId) {
                this.jumpMyOrder()
            } else {
                this.jumpMyBagCopy()
            }
        },
        sharePoster() {
            this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
                userId: this.userId,
                token: this.token,
                width: 240,
                height: 240,
                webUrl: "https://web.chaolu.com.cn/#/ordinary_004001",
                scene: JSON.stringify({webUrl: window.location.origin + '/#/superdeer-activity/gift-cash-back'})
            }).then(res => {
                this.userPoster = res.data.qrCode
                this.nickName = res.data.nickName
                this.headImg = res.data.headImg
            })
        },
        dealPrice(v, num = 2) {
            if (!v || typeof v !== "number") {
                return 0
            }
            return (v / 100).toFixed(num)
        },
        /**
         * 小程序广告转化
         * @param actionType 预定义的行为类型，目前只支持COMPLETE_ORDER（下单）及RESERVATION（表单预约）、CONFIRM_EFFECTIVE_LEADS（有效销售线索）
         */
        addUserAction(actionType) {
            if (!this.clickId) {
                return false
            }
            this.$axios.post(`${this.baseURLApp}/chaolu-wx-applet/action/addUserAction/v1`, {
                actionUrl: 'superdeer-activity/sell-membership/self',
                clickId: this.clickId,
                actionType: actionType
            }, false).then(res => {
                console.log('广告数据转化埋点：类型', actionType, '返回参数', res)
            }).catch(r => {
                console.log('广告数据转化埋点：类型', actionType, '埋点接口调用失败')
            })
        },
        isEmpty(data) {
            // console.log(typeof data)
            switch (typeof data) {
                case "number":
                    return data === 0
                case "object":
                    return (!data || Object.keys(data).length < 1);
                case "undefined":
                    return true
                case "boolean":
                    return false
                case "string":
                    return (data === 'undefined' || data === 'null' || data === '' || data === '0')
            }
        },
    },
};
</script>
<style lang="less" scoped>
.share-icon {
  width: 38px;
}
.new-card-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 32px 6px;

    .card-item {
        width: 334px;
        height: 168px;
        box-sizing: border-box;
        background: #F9FAFB;
        border-radius: 16px;
        border: 2px solid #ddd;
        flex-shrink: 0;
        margin-bottom: 18px;
        position: relative;
        padding: 32px 28px 0;

        &:nth-child(2n) {
            margin-left: 18px;
        }

        &.active {
            background-color: #FFFDE3;
            border-color: #FFDE00;

            .couponAfterPrice {
                background: linear-gradient(90deg, #FFF100 0%, #FFDE00 100%);
            }

            .item-tips {
                color: #A56309;
            }
        }

        .card-item-top {
            margin-bottom: 12px;
        }

        .card-name {
            color: #242831;
            font-size: 26px;
            font-weight: bold;
            line-height: 26px;
        }

        .item-tips {
            font-size: 20px;
            color: #6C727A;
            line-height: 20px;
            max-width: 8em;
        }

        .price {
            font-size: 64px;
            position: relative;
            top: 10px;
            color: #242831;
            //font-weight: bold;
            white-space: nowrap;
            font-family: BebasNeueBold;

            span {
                font-size: 28px;
            }
        }

        .originalPrice {
            font-size: 18px;
            text-decoration: line-through;
            color: #6C727A;
            font-weight: normal;
            line-height: 20px;
            white-space: nowrap;
        }

        .couponAfterPrice {
            height: 36px;
            background-color: #ECC589;
            border-radius: 8px;
            color: #A56309;
            font-size: 22px;
            font-weight: bold;
            padding: 0 8px;
            margin-bottom: 12px;
        }

        .discount-tag {
            position: absolute;
            right: -2px;
            top: -10px;
            height: 30px;
            background: #ED5C42;
            border-radius: 4px;
            font-size: 18px;
            color: #FFFFFF;
            padding: 0 8px;
            box-sizing: border-box;
        }
    }
}

.bottom-rule {
    padding: 48px 32px 62px;

    //background-color: white;
    h4 {
        line-height: 36px;
        font-size: 36px;
        color: #232831;
    }

    h5 {
        font-size: 24px;
        margin-top: 32px;
    }

    p {
        margin-top: 32px;
        font-size: 24px;
        font-weight: 400;
        line-height: 48px;
        color: #3C454E;
        white-space: pre-line;
    }
}

.refund-box {
    width: 750px;
    background: #FFFFFF;
    border-radius: 12px;

    .pay-img {
        padding-top: 56px;

        img {
            width: 100%;
            display: block;
        }
    }

    .refund-title {
        padding: 32px 0 32px;
        text-align: center;
        font-weight: bold;
        font-size: 34px;
        line-height: 48px;
        color: #242831;
    }

    .refund-content {
        padding: 0 32px;
        font-size: 28px;
        line-height: 44px;
        color: #9AA1A9;
        word-wrap: break-word;
        word-break: break-all;
    }

    .refund-info {
        margin-top: 30px;
        font-size: 28px;
        line-height: 28px;
        color: #242831;
        padding: 0 32px;

        span:last-child {
            font-weight: bold;
        }
    }

    .refund-btn {
        margin-top: 40px;
        padding: 28px 32px;
        border-top: 1px solid #eee;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 96px;
            width: 100%;
            font-size: 28px;
            border-radius: 12px;
            border: 1px solid #eee;
            color: #242831;
            box-sizing: border-box;

            &:first-child {
                font-weight: bold;
                background-color: #FFDE00;
                border-color: #FFDE00;
            }
        }
    }
}

.page-box {
    min-height: 100vh;
    padding-bottom: 300px;
    background-color: #F4F4F4;

    & > div {
        width: 750px;
    }

    .top-menu {
        padding-left: 32px;
        overflow: hidden;
        box-sizing: border-box;
        background-color: white;
        height: 66px;
        width: 750px;
        margin-bottom: 32px;

        div {
            span {
                font-size: 40px;
                color: #3C454E;
                font-weight: 500;
                margin-right: 6px;
            }

            .play-icon {
                width: 32px;
                height: 32px;
                transform: rotateZ(90deg);
                transition: all .3s;
                transform-origin: center center;
                font-size: 24px;

                &.active {
                    transform: rotateZ(-90deg);
                    right: 0;
                }
            }

        }
    }

    .page-h {
        position: relative;
        width: 750px;
        background: #f4f4f4;
        overflow: hidden;
        display: flex;
        justify-content: center;
        padding: 0 0 10px;

        & > div {
            width: 686px;
            height: 358px;
            border-radius: 16px;
            background: #fff no-repeat center center;
            background-size: 100% 100%;

            div {
                padding: 42px 0 0 32px;
                font-size: 48px;
                color: #fff;
                font-family: PuHuiTi;

                h3 {
                    margin-bottom: 32px;
                    line-height: 60px;
                    font-weight: normal;

                    & > span {
                        font-size: 36px;
                        margin-right: 4px;
                    }
                }
            }

            & > p {
                font-size: 18px;
                color: #FFFFFF;
                line-height: 32px;
                margin: 174px 0 0 32px;
                position: absolute;
                left: 32px;
                bottom: 40px;

                & > span {
                    background: rgba(0, 0, 0, 0.4);
                    padding: 8px;
                    border-radius: 6px;
                }
            }
        }
    }

    .voucher {
        padding: 16px 32px 0;

        div {
            padding: 0 4px 0 120px;
            height: 40px;
            border-radius: 0 2px 2px 0;
            border: 1px solid #F03C18;
            border-left: none;
            font-size: 24px;
            color: #F03C18;
            font-weight: bold;
            background: url("https://img.chaolu.com.cn/MINI/icon/voucher-icon1.png") no-repeat left center;
            background-size: 104px 40px;
            box-sizing: border-box;

            span {
                margin-right: 4px;
            }
        }
    }

    .card-box {
        background-color: white;

        &.bottom-btn {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 750px;
            padding: 0 0 calc(12px + env(safe-area-inset-bottom));
        }

        .order-card-tips {
            width: 686px;
            height: 70px;
            background: #ECF3FE;
            border-radius: 16px;
            color: #2D7EF5;
            font-size: 22px;
            line-height: 70px;
            margin: 32px auto;
            padding-left: 24px;
        }

        .card-tips {
            font-size: 22px;
            line-height: 34px;
            color: #3C454E;
            padding: 0 32px;
            text-align: justify;
            word-break: break-all;
        }

        .card-list {
            display: flex;
            align-items: center;
            overflow-x: auto;
            padding: 16px 32px 32px;
            box-sizing: border-box;
            width: 100vw;

            &::-webkit-scrollbar {
                display: none;
            }

            .card-item {
                height: 296px;
                position: relative;
                width: 224px;
                background: #F9FAFB;
                border-radius: 16px;
                border: 2px solid #DDDDDD;
                flex-shrink: 0;
                box-sizing: border-box;

                &.card-item + .card-item {
                    margin-left: 16px;
                }

                &.active {
                    background: #FFFDE3;
                    border-color: #FFDE00;

                    & > p {
                        font-weight: 600;
                        background: linear-gradient(90deg, #FFF100 0%, #FFDE00 100%);
                        color: #242831;
                    }

                    .item-tips {
                        color: #A56309;
                    }
                }

                .item-tips {
                    font-size: 20px;
                    line-height: 20px;
                    color: #6C727A;
                    margin-top: 20px;
                }

                .discount-tag {
                    position: absolute;
                    right: -2px;
                    top: -10px;
                    height: 30px;
                    background: #ED5C42;
                    border-radius: 4px;
                    font-size: 18px;
                    color: #FFFFFF;
                    padding: 0 8px;
                    box-sizing: border-box;
                }

                & > div {
                    height: 256px;
                    width: 100%;
                    padding: 40px 12px 0;
                    box-sizing: border-box;

                    p {
                        font-size: 28px;
                        color: #242831;
                        font-weight: bold;
                        word-break: break-all;
                        text-align: justify;
                        line-height: 32px;
                        height: 64px;
                    }

                    .price {
                        margin-top: 6px;
                        font-size: 56px;
                        color: #242831;
                        font-weight: bold;
                        font-family: BebasNeueBold;

                        span {
                            font-size: 28px;
                        }
                    }

                    .originalPrice {
                        font-size: 22px;
                        text-decoration: line-through;
                        color: #6C727A;
                        font-weight: normal;
                    }
                }

                & > p {
                    background: #ECC589;;
                    color: #A56309;
                    font-weight: bold;
                    font-size: 24px;
                    height: 44px;
                    width: 224px;
                    position: absolute;
                    left: -2px;
                    bottom: -2px;
                    border-radius: 0 0 16px 16px;
                }
            }
        }

        .select-voucher{
            width: 100vw;
            background: #FDEEEC;
            margin: 0 auto 0;
            padding: 24px 32px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            img{
                height: 32px;
                margin-right: 12px;
            }
            &>div{
                font-size: 24px;

                span{
                    color: #F03C18;
                    //font-weight: bold;
                }
            }
            &>p{
                display: flex;
                align-items: center;
                font-size: 24px;
                //color: #F03C18;
                span{
                    margin-left: 4px;
                }
            }
        }

        .buy-card-btn {
            width: 686px;
            height: 112px;
            margin: 32px auto 20px;
            //background-color: #242831;
            display: flex;
            align-items: center;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%;
            position: relative;
            border-radius: 24px;
            background-image: url("https://img.chaolu.com.cn/ACT/schedule-2023/btn_img.png");

            & > h4 {
                font-size: 64px;
                color: #F7DFB4;
                width: 360px;
                padding-left: 80px;
                box-sizing: border-box;
                font-weight: bold;
                position: relative;
                top: 2px;

                .s1 {
                    font-family: BebasNeueBold;
                    font-weight: normal;
                    font-size: 32px;
                }

                .s2 {
                    font-family: BebasNeueBold;
                }

                .s3 {
                    font-weight: normal;
                    color: #FFFFFF;
                    font-size: 24px;
                    margin-left: 12px;
                    text-decoration: line-through;
                }
            }

            & > div {
                display: flex;

                //padding-right: 8px;
                p {
                    width: 314px;
                    height: 112px;
                    //background: linear-gradient(90deg, #FFEF84 0%, #FFDE00 100%);
                    border-radius: 22px;
                    font-size: 32px;
                    font-weight: bold;
                    color: #242831;

                    &:last-child {
                        margin-left: 12px;
                    }
                }
            }

            &.purchased {
                background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-button1.png");
            }

            &.no-start {
                background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-button3.png");
            }

            &.end {
                background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-button2.png");
            }
        }

        .protocol {
            display: flex;
            align-items: flex-start;
            padding: 0 32px;

            &.shake {
                animation: shake 1s;
            }

            p {
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                background: url("https://img.chaolu.com.cn/ACT/invite-2022/icon7.png") no-repeat center center;
                background-size: 100% 100%;

                &.active {
                    background-image: url("https://img.chaolu.com.cn/ACT/invite-2022/icon6.png");
                }
            }

            div {
                font-size: 18px;
                line-height: 26px;
                color: #707072;
                padding-top: 7px;

                .s1 {
                    font-weight: bold;
                    color: #232831;
                }
            }
        }

        .gift-detail {
            margin-top: 32px;

            .gift-detail-top {
                background: #2A2D3C;
                padding: 48px 32px 20px;

                h4 {
                    font-size: 28px;
                    line-height: 28px;
                    color: #FFFFFF;
                    margin-bottom: 32px;
                    text-align: center;
                }

                & > div {
                    flex-wrap: wrap;

                    div {
                        position: relative;
                        margin-bottom: 20px;
                        margin-right: 16px;

                        &:nth-child(3n) {
                            margin-right: 0;
                        }

                        span {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 34px;
                            padding: 0 18px;
                            background: #FFDE00;
                            border-radius: 12px 0 12px 0;
                            color: #242831;
                            font-size: 20px;
                        }

                        img {
                            width: 218px;
                            height: 196px;
                            border-radius: 12px;
                            display: flex;
                        }

                        p {
                            width: 218px;
                            font-size: 20px;
                            line-height: 28px;
                            color: #FFFFFF;
                            font-weight: 500;
                            margin-top: 18px;
                        }
                    }
                }
            }

            .gift-detail-bottom {
                background: #F5F5F5;
                padding: 0 32px 48px;

                h4 {
                    font-size: 36px;
                    line-height: 36px;
                    color: #232831;
                }

                & > p {
                    font-size: 24px;
                    color: #3C454E;
                    line-height: 24px;
                    margin-top: 20px;
                }

                .gift-detail-item {
                    width: 686px;
                    //height: 332px;
                    background: #FFFFFF;
                    border-radius: 16px;
                    margin-top: 32px;
                    position: relative;

                    .gift-sort {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 88px;
                        height: 44px;
                        background: linear-gradient(135deg, #FEEEC6 0%, #F4D386 100%);
                        border-radius: 16px 0 16px 0;
                        color: #000000;
                        font-weight: bold;
                        font-size: 22px;
                    }

                    img {
                        flex: none;
                        width: 686px;
                        border-radius: 16px;
                        //height: fit-content;
                        display: block;
                        //height: 100%;
                        //object-fit: cover;
                        //height: fit-content;
                    }

                    & > div {
                        padding: 64px 36px 0;

                        div {
                            font-weight: bold;
                            line-height: 32px;
                            color: #232831;
                            font-size: 32px;

                            .gift-question {
                                font-size: 32px;
                            }

                            span {
                                margin-right: 10px;
                            }
                        }

                        p {
                            margin-top: 24px;
                            color: #6C727A;
                            line-height: 34px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }

}

.protocol-box {
    width: 630px;
    height: 900px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 0 auto;

    h4 {
        font-size: 36px;
        font-weight: 600;
        color: #242831;
        line-height: 36px;
        text-align: center;
        padding-top: 58px;
    }

    p {
        &::-webkit-scrollbar {
            display: none;
        }

        height: 570px;
        overflow-x: auto;
        text-align: left;
        margin: 32px auto 56px;
        width: 510px;
        font-size: 28px;
        font-weight: 400;
        color: #3C454E;
        line-height: 44px;
        white-space: pre-line;
    }

    div {
        margin: 0 auto;
        font-weight: bold;
        width: 510px;
        height: 96px;
        background: #FFDE00;
        border-radius: 8px;
        font-size: 32px;
        color: #242831;
        line-height: 32px;

        &.gray {
            background: #9AA1A9;
        }
    }
}

.error-box {
    width: 630px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 0 auto;
    padding-bottom: 48px;

    & > span {
        display: block;
        text-align: center;
        margin-bottom: 24px;
        font-size: 28px;
        font-weight: bold;
        color: #0a7aff;
    }

    h4 {
        font-size: 36px;
        font-weight: 600;
        color: #242831;
        line-height: 36px;
        text-align: center;
        padding-top: 58px;
    }

    p {
        text-align: center;
        margin: 32px auto 56px;
        width: 510px;
        font-size: 28px;
        font-weight: 400;
        color: #3C454E;
        line-height: 44px;
    }

    div {
        margin: 0 auto;
        font-weight: bold;
        width: 510px;
        height: 96px;
        background: #FFDE00;
        border-radius: 8px;
        font-size: 32px;
        color: #242831;
        line-height: 32px;
    }
}

// 支付成功弹窗
.pay-box {
    width: 590px;
    box-sizing: border-box;
    margin: 0 auto;
    background: white url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-success-bg.png") no-repeat center top;
    background-size: 100% 554px;
    border-radius: 16px;
    overflow: hidden;
    padding-bottom: 40px;

    &.big {
        width: 622px;
    }

    .pay-box-title {
        &.success {
            padding: 64px 0 0;
        }

        img {
            width: 128px;
            height: 128px;
            display: block;
            margin: 0 auto;
            padding: 64px 0 48px;
        }

        .small-right {
            width: 64px;
            height: 64px;
            padding: 0;
            margin: 0 16px 0 0;
        }

        .pay-success-tips {
            width: 444px;
            height: 28px;
            display: block;
            margin: 0 auto;
            padding: 48px 0 0;
        }

        div {
            font-weight: bold;
            color: #242831;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
        }
    }

    .pay-box-text {
        font-size: 28px;
        color: #242831;
        line-height: 40px;
        margin-top: 24px;
        text-align: center;
    }

    .pay-box-tips {
        font-size: 22px;
        color: #6C727A;
        line-height: 26px;
        text-align: center;
        width: 460px;
        margin: 40px auto 0;

        .s1 {
            color: #FF6E00;
            font-size: 22px;
        }
    }

    .pay-equit-box {
        margin: 40px 0 0;
        padding: 0 0 0 34px;

        .pay-box-item {
            width: 123px;
            height: 128px;
            margin: 0 22px 20px 0;
        }
    }

    .pay-share-btn {
        div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 96px;
            border-radius: 8px;
            border: 1px solid #FFDE00;
            box-sizing: border-box;
            background: #FFDE00;
        }

        .grey-btn {
            width: 206px;
            flex: none;
            margin: 0 24px 0 0;
            background-color: #fff;
            border: 1px solid #CCCCCC;
        }

        padding: 0 32px;
        height: 96px;
        margin: 48px auto 0;
        color: #242831;

    }
}

.result-close {
    width: 60px;
    height: 60px;
    margin: 48px auto 0;
    display: block;
}

// 海报样式
.share-poster-cont {
    position: relative;
    overflow: hidden;
    //width: 100 * @w;
    width: 654px;
    height: 968px;
    //width: 103.2vw;
    margin: 0 auto;
    box-sizing: border-box;

    img.share-bg {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.share-user {
    position: absolute;
    left: 32px;
    top: 32px;
    display: flex;
    align-items: center;

    img {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        margin-right: 24px;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
    }

    .use-header-name {
        font-size: 28px;
        font-weight: bold;
        color: #242831;
        line-height: 40px;
        //width: 45 * @w;
        width: 15em;
    }

    .use-header-text {
        font-size: 22px;
        font-weight: 400;
        color: #242831;
        line-height: 26px;
        margin-top: 12px;
    }
}

.share-poster-cont .poster-code-cont {
    position: absolute;
    right: 32px;
    bottom: 32px;
    width: 160px;
    height: 160px;
    padding: 8px;
    background: rgba(255, 255, 255, 0.35);;
    //box-sizing: border-box;
    border-radius: 16px;
}

.share-poster-cont .poster-code-img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    display: block;
    background-color: white;
    box-sizing: border-box;

}

.city-nav-box {
    display: flex;
    align-items: center;
    padding: 30px 32px 32px;
    width: 100vw;
    box-sizing: border-box;
    overflow-x: auto;
    background-color: #F4F4F4;

    div {
        //width: 148px;
        flex-shrink: 0;
        height: 56px;
        background: #fff;
        border-radius: 8px;
        color: #666666;
        font-size: 22px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #999999;
        margin-right: 24px;
        box-sizing: border-box;

        &.active {
            font-weight: bold;
            color: #242831;
            border-color: #242831;
        }
    }
}</style>
